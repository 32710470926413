import React from 'react';
import Container from '../components/container';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Headline from '../components/headline';

const Nosotros = () => (
  <Layout>
    <SEO
      title="Nosotros"
      description="AMAMOS VIVIR CADA DÍA es una asociación sin fines de lucro que nace del
        amor a la humanidad, creada para ofrecer ayuda integral a niños y jóvenes
        que padecen alguna enfermedad crónico degenerative y a sus familias."
    />
    <Container>
      <Headline>Nosotros</Headline>
      <p>
        AMAMOS VIVIR CADA DÍA es una asociación sin fines de lucro que nace del
        amor a la humanidad, creada para ofrecer ayuda integral a niños y
        jóvenes que padecen alguna enfermedad crónico degenerative y a sus
        familias. La asociación esta diseñada para trabajar mediante convenios
        de colaboración con Instituciones Publicas, Privadas, Asociaciones
        Civiles, del Estado de México, Nacionales e Internacionales.
      </p>
      <p>
        Somos miembros de Red Expo Social, una red de más de 132 asociaciones
        del Estado de México quienes a la vez estamos conectados con cientos de
        asociaciones en todo el país y a nivel internacional.
      </p>
    </Container>
  </Layout>
);

export default Nosotros;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Headline = ({ children }) => <StyledHeadline>{children}</StyledHeadline>;

Headline.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Headline;

const StyledHeadline = styled.h1`
  margin-top: 3rem;
`;
